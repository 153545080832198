import React from 'react';

function HeadChristmnasTree() {
  return (
    <>
      <div className="vetka left" />
      <div className="vetka right" />
    </>
  );
}

export default HeadChristmnasTree;
