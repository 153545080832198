import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper';
import { isEmpty } from 'lodash';
import { Loader } from '../components';
import 'swiper/swiper-bundle.css';
import { fromStore, slidersSelector } from '../selectors';
import { fetchSliders } from '../actions';
import { getImageUrl } from '../utils';

export function ADS() {
  const dispatch = useDispatch();
  const loader = useSelector(fromStore.slidersLoaderSelector);
  const slides = useSelector(slidersSelector);

  useEffect(() => {
    if (slides.length === 0) {
      dispatch(fetchSliders());
    }
  }, []);

  if (loader) return <Loader banner />;

  if (isEmpty(slides)) return null;

  return (
    <Swiper
      style={{ zIndex: '-2' }}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      pagination={{
        dynamicBullets: true,
      }}
      navigation
      modules={[Navigation, Autoplay, Pagination]}
      className="mySwiper"
    >
      {slides.map((slide) => (
        <SwiperSlide key={slide.id} style={{ backgroundColor: '#fff0' }}>
          <img className="rounded" src={getImageUrl(slide.image)} alt={slide.title} />
        </SwiperSlide>
      ))}
    </Swiper>

  );
}
