import React from 'react';
import '../css/Snowflakes.css';

function Snowflakes() {
  const snowflakes = Array.from({ length: 300 }).map((_, index) => {
    const size = `${(Math.random() * 0.2 + 0.3).toFixed(2)}em`;
    const animationDuration = `${(Math.random() * (25 - 18) + 18).toFixed(2)}s`;
    const left = `${Math.random() * 100}%`;
    const top = `${Math.random() * -60}%`;

    return (
      <div
        key={`snowflake-${index}`} // eslint-disable-line react/no-array-index-key
        style={{
          width: size,
          height: size,
          left,
          top,
          animationDuration,
        }}
        className="snowflake"
      />
    );
  });

  return (
    <div className="snowflakes">
      {snowflakes}
      <div className="snowdrift" />
    </div>
  );
}

export default Snowflakes;
